import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XAutoComplete} from "@michalrakus/x-react-web-lib/XAutoComplete";
import {Utils} from "../../Utils";
import {EnumEnum, SluzbaEnum} from "../../common/enums";
import {XFormAutoCompleteColumn, XFormColumn, XFormDataTable2} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {VykazStreetwork} from "../../model/zapisy/vykaz-streetwork.entity";
import {Zapis} from "../../model/zapisy/zapis.entity";
import type {XFormProps} from "@michalrakus/x-react-web-lib/XFormBase";
import {XFormBase} from "@michalrakus/x-react-web-lib/XFormBase";
import {XButtonIconMedium} from "@michalrakus/x-react-web-lib/XButtonIconMedium";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XUserDePaul} from "../../model/user/x-user-de-paul.entity";
import {VykazXUser} from "../../model/zapisy/vykaz-x-user.entity";
import {XErrors} from "@michalrakus/x-react-web-lib/XErrors";
import {FindUsersRequest} from "../../common/user-api";
import {MiestoMoznehoKontaktuBrowse} from "./MiestoMoznehoKontaktuBrowse";
import {MiestoMoznehoKontaktuForm} from "./MiestoMoznehoKontaktuForm";
import {ZapisPanel} from "./ZapisPanel";
import {ZapisBaseForm} from "./ZapisBaseForm";
import {XEditor} from "@michalrakus/x-react-web-lib/XEditor";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import {XFormScrollable} from "../XFormScrollable";
import {XFieldChangeEvent} from "@michalrakus/x-react-web-lib/XFieldChangeEvent";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import {XEnum} from "../../model/user/x-enum.entity";
import {dateAsUI, dateFromModel} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {MiestoMoznehoKontaktu} from "../../model/zapisy/miesto-mozneho-kontaktu.entity";

@Form("VykazStreetwork")
export class VykazStreetworkForm extends ZapisBaseForm {

    userStreetworkList: XUserDePaul[] = [];

    constructor(props: XFormProps) {
        super(props);

        // najoinujeme tieto asociacie (lebo cela asociacia zapisList je odprogramovana rucne)
        // najoinujeme zaznam KlientSluzba, Klient a aj pohlavie, obec a stat - atributy na tychto zaznamoch su zobrazene v autocomplete na vyber klienta
        this.addField("zapisList.klientSluzba.klient.pohlavie.name");
        this.addField("zapisList.klientSluzba.klient.obec.nazov");
        this.addField("zapisList.klientSluzba.klient.stat.nazov");
        // najoinujeme aj klientSluzba.klientSluzbaZakazList - pouziva sa na vycervenenie klienta v autocomplete
        this.addField("zapisList.klientSluzba.klientSluzbaZakazList.id");
        this.addField("zapisList.sluzba.kod");
        this.addField("zapisList.psychPoradXUser.name");
        this.addField("zapisList.specPoradXUser.name");
        this.addField("zapisList.specPoradXUser2.name");

        // spracovanie custom validacie pre oneToMany asociaciu zapisList
        this.addAssocToValidate("zapisList");
        this.addAssocToSort("zapisList", "id");

        this.onChangeDatum = this.onChangeDatum.bind(this);
        this.removeVykazXUser = this.removeVykazXUser.bind(this);
    }

    async componentDidMount() {
        await super.componentDidMount();

        // nacitame si zoznam userov pre stretwork (tych budeme ponukat v autocomplete v casti Pracovnici)
        this.userStreetworkList = await XUtils.fetchMany('find-users', {sluzbaKod: SluzbaEnum.streetwork} satisfies FindUsersRequest);

        // metoda vytvorAtributyHistoria() potrebuje mat k dispozicii vytvorene vsetky komponenty typu XFieldSetBase
        // a tie su vytvorene az ked sa zavola callback v setState (mozno sa da zavolat aj niekedy skor ale takto to funguje, napr. v preInitForm to nefunguje)
        // (tiez prava musia byt nacitane)
        this.setStateXForm(() => this.vytvorAtributyHistoria()); // aby sa prejavili zmeny ktore zavisia od nacitanych zoznamov
    }

    createNewObject(): XObject {
        return {
            datum: XUtilsCommon.today(),
            vykazXUserList: [], vykazMestskaCastList: [], vykazMiestoMoznehoKontaktuList: [], zapisList: [],
            version: 0
        };
    }

    async vytvorAtributyHistoria() {
        const vykazStreetwork: VykazStreetwork | null = this.getVykazStreetwork();
        if (!vykazStreetwork) {
            throw "Unexpected error - vykazStreetwork is null";
        }
        for (const zapis of vykazStreetwork.zapisList) {
            await ZapisPanel.vytvorAtributyHistoria(zapis);
        }

        this.setStateXForm(); // aby sa prejavili zmeny ktore zavisia od nacitanych zoznamov
    }

    // pomocna metodka
    private getVykazStreetwork(): VykazStreetwork | null {
        return this.state.object;
    }

    // pomocna metodka
    // private getZapisList(): Zapis[] {
    //     const vykazStreetwork: VykazStreetwork = this.getXObject() as VykazStreetwork;
    //     return vykazStreetwork.zapisList;
    // }

    async onChangeDatum(e: XFieldChangeEvent<VykazStreetwork>) {
        // zapiseme datum do zapisov - datum v zapisoch pouziva ZapisPanel.vytvorAtributyHistoria (pripadne ina funkcionalita ho moze pouzivat)
        for (const zapis of e.object.zapisList) {
            zapis.datum = e.object.datum;
        }

        // zmenil sa datum, prepocitame historiu atributov
        for (const zapis of e.object.zapisList) {
            await ZapisPanel.vytvorAtributyHistoria(zapis);
        }
        // kedze sme pouzili await, tak musime zavolat setState (framework necaka a vola setState hned)
        this.setStateXForm();
    }

    itemTemplateMiestoMoznehoKontaktu(suggestion: MiestoMoznehoKontaktu, index: number, createStringValue: boolean, defaultValue: (suggestion: any) => string): React.ReactNode {
        if (createStringValue) {
            // hodnota do inputu - iba miesto
            return suggestion.miesto;
        }
        else {
            // hodnota do dropdown panelu - dame miesto + mestska cast
            return [suggestion.miesto, suggestion.mestskaCast?.name].join(", ");
        }
    }

    removeVykazXUser(vykazXUser: VykazXUser) {
        // skontrolujeme, ci mozme vymazat
        if (vykazXUser.xUser) {
            const vykazStreetwork: VykazStreetwork | null = this.getVykazStreetwork();
            if (vykazStreetwork) {
                for (const zapis of vykazStreetwork.zapisList) {
                    if ((zapis.specPoradXUser && zapis.specPoradXUser.id === vykazXUser.xUser.id)
                        || (zapis.specPoradXUser2 && zapis.specPoradXUser2.id === vykazXUser.xUser.id)) {
                        alert(`Nie je dovolené vymazať pracovníka ${vykazXUser.xUser.name}, pracovník je zapísaný v špecializovanom poradenstve na zázname pre klienta ${zapis.klientSluzba?.klient?.menoPriezviskoPrezyvka}`);
                        return;
                    }
                    if (zapis.psychPoradXUser && zapis.psychPoradXUser.id === vykazXUser.xUser.id) {
                        alert(`Nie je dovolené vymazať pracovníka ${vykazXUser.xUser.name}, pracovník je zapísaný v psychologickom poradenstve na zázname pre klienta ${zapis.klientSluzba?.klient?.menoPriezviskoPrezyvka}`);
                        return;
                    }
                }
            }
        }
        // vymazeme
        this.onTableRemoveRow("vykazXUserList", vykazXUser);
    }

    addZapis() {
        const zapis: Zapis = {
            id: undefined!, // vygeneruje this.onTableAddRow
            sluzba: Utils.getCurrentSluzba()!,
            vykazStreetwork: undefined!, // v tomto formulari sa tato asociacia nepouziva
            klientSluzba: null,
            datum: null!, // pri save doplnime z VykazStreetwork
            vseobecnyZapis: null,
            atributy: {},
            psychPoradCas: null,
            psychPoradXUser: null,
            specPoradCas: null,
            specPoradXUser: null,
            specPoradXUser2: null,
            specPoradZapis: null,
            lujzaPoradProjekt: null,
            vincentPoradProjekt: null,
            modifDate: null,
            modifXUser: null,
            version: 0 // nepouziva sa
        }
        this.onTableAddRow("zapisList", zapis, "id");
    }

    removeZapis(zapis: Zapis) {
        if (window.confirm(`Chcete naozaj vymazať celý zápis${zapis.klientSluzba ? ' pre klienta ' + zapis.klientSluzba.klient.menoPriezviskoPrezyvka : ''}?`)) {
            this.onTableRemoveRow("zapisList", zapis);
        }
    }

    // overrides method in XFormBase
    async validate(vykazStreetwork: VykazStreetwork): Promise<XErrors> {
        const errors: XErrors = {};

        // okontrolujeme, ci nevyrabame duplicitny zaznam (nedalo sa nahodit unique constraint koli datam z migracie)
        const datum: Date | null = vykazStreetwork.datum;
        const tim: XEnum | null = vykazStreetwork.tim;
        if (datum && tim) {
            let vykazStreetworkList: any[] = await XUtils.fetchRows('VykazStreetwork',
                {where: "[datum] = :datum AND [tim] = :timId AND [id] <> :vykazStreetworkId",
                    params: {datum: datum, timId: tim.id, vykazStreetworkId: vykazStreetwork.id ?? 0}});
            if (vykazStreetworkList.length > 0) {
                errors.tim = `Už existuje záznam s dátumom ${dateAsUI(dateFromModel(datum))} a tímom ${tim.name}.`;
            }
        }

        for (const zapis of vykazStreetwork.zapisList) {
            const errorsZapis: XErrors = ZapisPanel.validateZapis(vykazStreetwork, zapis);
            //zapiseme chyby do zaznamu zapis do specialneho technickeho atributu
            XFormBase.saveErrorsIntoXRowTechData(zapis, errorsZapis);
        }

        return errors;
    }

    preSave(object: VykazStreetwork) {
        super.preSave(object);

        // upravy zapisov
        for (const zapis of object.zapisList) {
            zapis.datum = object.datum; // datum kopirujeme z vykazu
            zapis.modifDate = object.modifDate; // ak by raz niekto pozeral/upravoval po jednotlivych zaznamoch, nech vidi nejaku hodnotu
            zapis.modifXUser = object.modifXUser; // ak by raz niekto pozeral/upravoval po jednotlivych zaznamoch, nech vidi nejaku hodnotu
            zapis.atributyHistoria = undefined; // info o historii atributov nechceme posielat na backend
            zapis.xFieldSetBaseRef = undefined; // referenciu xFieldSetBaseRef nechceme posielat na backend
        }
    }

    createPanelZapis(vykazStreetwork: VykazStreetwork, zapis: Zapis): React.ReactNode {

        return <ZapisPanel key={zapis.id} zapisForm={false} vykazStreetwork={vykazStreetwork}
                   zapis={zapis} onChangeZapis={this.onChangeForm} removeZapis={() => this.removeZapis(zapis)}
                   userSpecPoradList={this.userSpecPoradList}
                   userPsychPoradList={this.userPsychPoradList}
                   idSluzbaRolaPravaMap={this.state.idSluzbaRolaPravaMap}
                   kodSluzbaRolaPravaMap={this.state.kodSluzbaRolaPravaMap}
                   onEditFieldChange={this.onEditFieldChange}/>;
    }

    createZapisy(): React.ReactNode {
        const vykazStreetwork: VykazStreetwork | null = this.getVykazStreetwork();
        if (vykazStreetwork === null) { // este nenacital objekt v componentDidMount()
            return undefined;
        }

        const zapisList: Zapis[] = vykazStreetwork.zapisList;
        const elemZapisList: React.ReactNode[] = [];
        for (const zapis of zapisList) {
            elemZapisList.push(this.createPanelZapis(vykazStreetwork, zapis));
        }
        return (
            <div>
                <div className="flex justify-content-center">
                    <div style={{fontSize: '120%'}}>Záznamy</div>
                </div>
                {elemZapisList}
                <div className="flex justify-content-center">
                    {/*    <XButtonIconMedium icon="pi pi-check" onClick={() => this.addZapis()}/> */}
                    <XButton icon="pi pi-plus" label="Pridať záznam" onClick={() => this.addZapis()} style={{backgroundColor:'green'}}/>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <XFormScrollable form={this} headerFooterHeight="8rem" widthFitContent={true}>
                    <div className="x-form-row">
                        <div className="x-form-col">
                            <div className="x-form-row">
                                <XInputDate form={this} field="datum" label="Dátum" labelStyle={{width:'6rem'}}/>
                                <XFormHeader form={this} label="Denník" style={{marginTop: '0rem', marginBottom: '1rem', marginLeft: '2rem', marginRight: '1.5rem'}}/>
                                <div className="x-form-inline-row">
                                    <XInputDate form={this} field="modifDate" label="Modif." labelStyle={{width:'4.5rem'}} readOnly={true}/>
                                    <XInputText form={this} field="modifXUser.name" labelStyle={{width:'0rem'}} inputStyle={{width:'10rem'}} readOnly={true}/>
                                    {!this.isAddRow() ? <XInputDecimal form={this} field="id" labelStyle={{width:'0rem'}} inputStyle={{width:'4rem'}} readOnly={true}/> : null}
                                </div>
                            </div>
                            <div className="x-form-row">
                                <div className="x-form-col">
                                    <XAutoComplete form={this} assocField="palivoVIgnise" displayField="name" label="Palivo v Ignise" labelStyle={{width:'9.5rem'}}
                                                   filter={Utils.enumFilter(EnumEnum.palivoVIgnise)} sortField="enumOrder" width="8.35rem"/>
                                    <XAutoComplete form={this} assocField="tim" displayField="name" label="Tím" labelStyle={{width:'9.5rem'}}
                                                   filter={Utils.enumFilter(EnumEnum.streetworkTim)} sortField="enumOrder" width="8.35rem"/>
                                    <XFormDataTable2 form={this} assocField="vykazXUserList"
                                                     onClickRemoveRow={this.removeVykazXUser}>
                                        <XFormColumn field="id" header="ID" readOnly={true} width="3rem" columnViewStatus={false}/>
                                        <XFormAutoCompleteColumn assocField="xUser" displayField="name" header="Pracovník" width="15rem"
                                                                 suggestions={this.userStreetworkList}/>
                                    </XFormDataTable2>
                                </div>
                                <div className="x-form-col">
                                    <XFormDataTable2 form={this} assocField="vykazMestskaCastList" label="Mestské časti - kilometre, hodiny">
                                        <XFormAutoCompleteColumn assocField="mestskaCast" displayField="name" header="Mestská časť" width="12rem" filter={Utils.enumFilter(EnumEnum.mestskaCast)}/>
                                        <XFormColumn field="kilometre" header="Kilometre" width="6rem"/>
                                        <XFormColumn field="hodinyCelkovo" header="Hodiny celkovo" width="8rem"/>
                                        <XFormColumn field="hodinyVTerene" header="Hodiny v teréne" width="8rem"/>
                                    </XFormDataTable2>
                                </div>
                            </div>{/* x-form-row pre stlpce */}
                            <XFormDataTable2 form={this} assocField="vykazMiestoMoznehoKontaktuList" label="Miesta možného kontaktu">
                                <XFormColumn field="miestoMoznehoKontaktu.mestskaCast.name" header="Mestská časť" readOnly={true} width="10rem"/>
                                <XFormAutoCompleteColumn assocField="miestoMoznehoKontaktu" displayField={["miesto", "mestskaCast.name"]} fields={["mestskaCast.name"]}
                                                         itemTemplate={this.itemTemplateMiestoMoznehoKontaktu}
                                                         suggestionsLoad="lazy" lazyLoadMaxRows={30}
                                                         header="Miesto možného kontaktu" width="24rem" scrollHeight="24rem"
                                                         filter={XUtilsCommon.filterAnd(Utils.filterCurrentSluzba(), {where: `[mestskaCast] IS NOT NULL`, params: {}})}
                                                         searchBrowse={<MiestoMoznehoKontaktuBrowse/>} assocForm={<MiestoMoznehoKontaktuForm/>}/>
                                <XFormColumn field="miestoMoznehoKontaktu.poznamka" header="Poznámka" readOnly={true} width="20rem"/>
                            </XFormDataTable2>
                            <XEditor form={this} field="priebehSluzby" label="Priebeh služby" labelOnTop={true} inputStyle={{width: '100%', maxWidth: '56rem'}}/>
                            {this.createZapisy()}
                        </div>
                    </div>
                </XFormScrollable>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
