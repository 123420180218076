import React, {useEffect, useState} from "react";
import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable,
    XMultilineRenderType
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {Utils} from "../../Utils";
import {ZapisForm} from "./ZapisForm";
import {EnumEnum, SluzbaEnum} from "../../common/enums";
import {KlientSluzbaForm} from "../klient/KlientSluzbaForm";
import {DataTableFilterMeta} from "primereact/datatable";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {Zapis} from "../../model/zapisy/zapis.entity";
import {XHtmlRenderer} from "@michalrakus/x-react-web-lib/XHtmlRenderer";
import {
    XFieldSetBase,
    XFieldSetMeta,
    XFieldXFieldMetaMap
} from "@michalrakus/x-react-web-lib/lib/components/XFieldSet/XFieldSetBase";
import {XFieldMeta} from "./XFieldSetBase";
import {XExpansionStateType, XExpansionSwitch} from "./XExpansionSwitch";
import {useXStateSession} from "@michalrakus/x-react-web-lib/useXStateSession";
import {VykazXUser} from "../../model/zapisy/vykaz-x-user.entity";
import {Divider} from "primereact/divider";

export const ZapisBrowse = (props: XBrowseProps & {filters?: DataTableFilterMeta; stateKey?: string}) => {

    const sluzbaEnum: SluzbaEnum | undefined = Utils.getCurrentSluzba()?.kod as SluzbaEnum;

    const [xFieldSetMap, setXFieldSetMap] = useState<XFieldXFieldMetaMap>(new Map<string, XFieldMeta>);
    // boolean stav dataLoaded vytiahnuty z XLazyDataTable, aby sme mohli zavolat reload dat
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [multilineSwitchValue, setMultilineSwitchValue] = useXStateSession<XMultilineRenderType>(`zapis-browse-${sluzbaEnum}-multiline-switch-value`, "allLines"); // init value

    // osetrovne maju jednoriadkovy popis, nastavime jednoriadkovy rezim, soc porad ma velke texty, nastavime rezim "expanded" (plati len pre desktop)
    const expansionSwitchValueInit: XExpansionStateType = (sluzbaEnum === SluzbaEnum.noclaharen || sluzbaEnum === SluzbaEnum.alzbetaOsetrovna ? "colapsed" : "expanded");
    const [expansionSwitchValue, setExpansionSwitchValue] = useXStateSession<XExpansionStateType>(`zapis-browse-${sluzbaEnum}-expansion-switch-value`, expansionSwitchValueInit); // init value

    // parameter [] zabezpeci ze sa metoda zavola len po prvom renderingu (a nie po kazdej zmene stavu (zavolani setNieco()))
    useEffect(() => {
        if (XUtils.isMobile()) {
            loadXFieldSetMap();
        }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // kedze nevieme zavolat metodu XLazyDataTable.setupExpandedRows(value, switchValue); (myslim ze sa daju volat len metody class component-ov)
        // tak pouzivame tento workaround a volame refresh DB (ten vola setupExpandedRows(value, switchValue)) - preblikne to ale kaslat na to...
        // (useEffect pouzivame, aby metoda showExpandedRowDesktop bezala az ked je expansionSwitchValue nastaveny)
        setDataLoaded(false);
    }, [expansionSwitchValue]); // Runs after `expansionSwitchValue` changes

    const loadXFieldSetMap = async () => {
        // in the future - take from some cache, not from DB
        const xFieldSetMetaList: XFieldSetMeta[] = await XUtils.fetchRows('XFieldSetMeta', {where: "[fieldSetId] = :fieldSetId", params: {fieldSetId: "zapis"}});
        // check
        if (xFieldSetMetaList.length !== 1) {
            throw `FieldSetId "zapis" was not found in DB in the table for Entity XFieldSetMeta`;
        }
        // save created structure
        // pozor! sposobuje dvojnasobne volanie selectov z XLazyDataTable
        setXFieldSetMap(XFieldSetBase.createXFieldXFieldMetaMap(xFieldSetMetaList[0]));
    }


    const showExpandedRowDesktop = (zapis: Zapis, switchValue: XMultilineRenderType): boolean => {
        return expansionSwitchValue === "expanded" && zapis.vseobecnyZapis !== null;
    };

    const rowExpansionTemplateDesktop = (zapis: Zapis) => {
        return (
            <XHtmlRenderer htmlValue={zapis.vseobecnyZapis} renderType={multilineSwitchValue} fewLinesCount={2}/>
        );
    };

    const showExpandedRowMobile = (zapis: Zapis, switchValue: XMultilineRenderType): boolean => {
        return switchValue !== "singleLine" && (zapis.vykazStreetwork?.vykazXUserList?.length !== 0 || zapis.vseobecnyZapis !== null || (zapis.atributy !== null && Object.keys(zapis.atributy).length > 0));
    };

    const rowExpansionTemplateMobile = (zapis: Zapis) => {
        let userNameList: string[] | undefined = undefined;
        if (zapis.vykazStreetwork?.vykazXUserList?.length !== 0) {
            userNameList = zapis.vykazStreetwork?.vykazXUserList.map((value: VykazXUser) => value.xUser.name);
        }
        const mameAtributy: boolean = (zapis.atributy !== null && Object.keys(zapis.atributy).length > 0);
        return (
            <div>
                {userNameList ? <div>{userNameList.join(", ")}</div> : null}
                {userNameList && (mameAtributy || zapis.vseobecnyZapis !== null) ? <Divider className="x-row-expansion-mobile-divider"/> : null}
                {mameAtributy ? <div className="x-multiline-content">{XFieldSetBase.xFieldSetValuesAsUI(zapis.atributy, xFieldSetMap)}</div> : null}
                {mameAtributy && zapis.vseobecnyZapis !== null ? <Divider className="x-row-expansion-mobile-divider"/> : null}
                <XHtmlRenderer htmlValue={zapis.vseobecnyZapis} renderType={multilineSwitchValue} fewLinesCount={2}/>
            </div>
        );
    };

    const onResetTable = () => {
        // viac menej kozmeticka zalezitost - ked user klikne na reset filter, tak sa switch vrati do init stavu
        setExpansionSwitchValue(expansionSwitchValueInit);
    }

    const onAddRow = () => {

        if (!Utils.getCurrentSluzba()) {
            alert("Vyberte najprv službu.");
            return;
        }

        // if (Utils.getCurrentSluzba()?.kod === SluzbaEnum.streetwork) {
        //     alert("Zápis pre streetwork je dovolené vytvoriť len cez voľbu Zápisy - streetwork.");
        //     return;
        // }

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<ZapisForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<ZapisForm id={selectedRow.id}/>);
    }

    const rowClassNameDesktop = (row: Zapis) => {
        if (expansionSwitchValue === 'expanded' && row?.klientSluzba?.klient?.menoPriezviskoPrezyvka) {
            return 'zapis-hlavny-riadok';
        }
        else {
            return '';
        }
    };

    const rowClassNameMobile = (row: Zapis) => {
        // na mobile pouzivame len multiline switch
        if (multilineSwitchValue !== 'singleLine' && row?.klientSluzba?.klient?.menoPriezviskoPrezyvka) {
            return 'zapis-hlavny-riadok';
        }
        else {
            return '';
        }
    };

    const renderDesktop = () => {

        const [fieldList, minLength]: [string[], number] = Utils.klientSluzbaFieldListForAutoComplete();

        // ak sme v expanded rezime, potrebujeme pridat medzi full text stlpce aj stlpec "vseobecnyZapis" (ma viewStatus = false)
        const fullTextSearchColumns: string[] = ["datum", "klientSluzba.klient.menoPriezviskoPrezyvka", "vseobecnyZapis", "atributy"];
        if (sluzbaEnum === SluzbaEnum.streetwork) {
            fullTextSearchColumns.push("vykazStreetwork.tim.name");
            fullTextSearchColumns.push("vykazStreetwork.vykazXUserList.xUser.name");
        }
        if (sluzbaEnum === SluzbaEnum.lujzaPoradenstvo) {
            fullTextSearchColumns.push("lujzaPoradProjekt.name");
        }
        if (sluzbaEnum === SluzbaEnum.vincentPoradenstvo) {
            fullTextSearchColumns.push("vincentPoradProjekt.name");
        }

        // niektoremu zo stlpcov Zapis/Klikacky nastavime restWidth = availWidth - <ostatne stlpce>, aby nam nevznikal horizontalny scrollbar na malych displayoch
        // width="calc(100vw - <ostatne stlpce>)" nefunguje, XLazyDataTable alebo DataTable nam to meni na calc(100vw), pouzijeme window.screen.availWidth
        // mozno sa to da aj nejako sikovnejsie cez nejaky fit-content alebo primereact atribut...
        let otherColumnsWidth: number = 0;
        const idColumnWidth: string = "5rem";
        if (Utils.isUserAdmin()) {
            otherColumnsWidth += XUtils.toPX0(idColumnWidth); // ID
        }
        otherColumnsWidth += XUtils.toPX0("7.25rem"); // Dátum
        const timColumnWidth: string = "6rem";
        const pracovniciColumnWidth: string = "12rem";
        if (sluzbaEnum === SluzbaEnum.streetwork) {
            otherColumnsWidth += XUtils.toPX0(timColumnWidth); // Tim
            otherColumnsWidth += XUtils.toPX0(pracovniciColumnWidth); // Pracovnici
        }
        const klientColumnWidth: string = (expansionSwitchValue === "colapsed" ? "12rem" : "17rem");
        otherColumnsWidth += XUtils.toPX0(klientColumnWidth); // Klient
        const atributyColumnWidth: string = "20rem";
        if (expansionSwitchValue === "colapsed") {
            otherColumnsWidth += XUtils.toPX0(atributyColumnWidth); // Klikacky
        }
        const lujzaProjektColumnWidth: string = "9rem";
        if (sluzbaEnum === SluzbaEnum.lujzaPoradenstvo) {
            otherColumnsWidth += XUtils.toPX0(lujzaProjektColumnWidth); // Projekt
        }
        const vincentProjektColumnWidth: string = "10rem";
        if (sluzbaEnum === SluzbaEnum.vincentPoradenstvo) {
            otherColumnsWidth += XUtils.toPX0(vincentProjektColumnWidth); // Projekt
        }

        // zohladnime nejake min/max, ak je display prilis uzky/siroky
        let viewWidth: number = XUtils.getViewWidth();
        const maxViewWidth: number = XUtils.toPX0("120rem");
        if (viewWidth > maxViewWidth) {
            viewWidth = maxViewWidth; // tabulka nebude natiahnuta na celu sirku display-a
        }

        let restWidth: number = viewWidth - otherColumnsWidth - XUtils.toPX0("2.5rem"); // 2.5 su margins + vertikalny scrollbar

        const minColumnWidth: number = XUtils.toPX0("15rem");
        if (restWidth < minColumnWidth) {
            restWidth = minColumnWidth; // bude horizontalny scrollbar
        }

        const restColumnWidth: string = `${restWidth}px`;

        return (
            <div>
                <XLazyDataTable entity="Zapis" stateKey={props.stateKey ?? `zapis-browse-${sluzbaEnum}`}
                                label={Utils.isSluzbaNoclaharen() ? "Ošetrenia" : "Zápisy"} sortField={[{field: "datum", order: -1}, {field: "id", order: -1}]} rows={30}
                                showExpandedRow={showExpandedRowDesktop} rowExpansionTemplate={rowExpansionTemplateDesktop}
                                dataLoadedState={[dataLoaded, setDataLoaded]}
                                filters={props.filters} customFilter={Utils.filterCurrentSluzba()}
                                fullTextSearch={fullTextSearchColumns}
                                onResetTable={onResetTable} onAddRow={onAddRow} onEdit={onEdit} removeRow={true}
                                appButtons={props.filters ? <XButton key="naspet" label="Naspäť" onClick={() => (props as any).openForm(null)}/> : undefined} /* ak sme prisli z VykazStreetworkHlavickaForm, zobrazime button Naspet */
                                displayed={props.displayed}
                                rowClassName={rowClassNameDesktop}
                                multilineSwitch={true} multilineSwitchValue={[multilineSwitchValue, setMultilineSwitchValue]}
                                headerElementRight={<XExpansionSwitch value={expansionSwitchValue} onChange={(switchValue: XExpansionStateType) => {setExpansionSwitchValue(switchValue); /*setupExpandedRows(value, switchValue);*/}} className="m-1"/>}>
                    <XLazyColumn field="id" header="ID" width={idColumnWidth} columnViewStatus={Utils.isUserAdmin()}/>
                    <XLazyColumn field="datum" header="Dátum" betweenFilter="column"/>
                    <XLazyColumn field="vykazStreetwork.tim.name" header="Tím" width={timColumnWidth} autoFilter={true}
                                 columnViewStatus={sluzbaEnum === SluzbaEnum.streetwork} dropdownInFilter={true} dropdownFilter={Utils.enumFilter(EnumEnum.streetworkTim)}/>
                    <XLazyColumn field="vykazStreetwork.vykazXUserList.xUser.name" header="Pracovníci" width={pracovniciColumnWidth}
                                 columnViewStatus={sluzbaEnum === SluzbaEnum.streetwork}/>
                    <XLazyColumn field="klientSluzba.klient.menoPriezviskoPrezyvka" header="Klient" width={klientColumnWidth} autoFilter={true}
                                 autoComplete={{
                                     assocField: "klientSluzba", field: fieldList, filter: Utils.filterCurrentSluzba(), valueForm: <KlientSluzbaForm/>,
                                     lazyLoadMaxRows: 15, minLength: minLength, scrollHeight: "50rem"
                                 }}/>
                    <XLazyColumn field="vseobecnyZapis" header="Zápis" width={restColumnWidth} contentType="html" columnViewStatus={expansionSwitchValue === "colapsed"}/>
                    <XLazyColumn field="atributy" fieldSetId="zapis" header="Klikačky" width={expansionSwitchValue === "colapsed" ? atributyColumnWidth : restColumnWidth}/>
                    <XLazyColumn field="lujzaPoradProjekt.name" header="Projekt" width={lujzaProjektColumnWidth} columnViewStatus={sluzbaEnum === SluzbaEnum.lujzaPoradenstvo} dropdownInFilter={true} dropdownFilter={Utils.enumFilter(EnumEnum.lujzaPoradProjekt)}/>
                    <XLazyColumn field="vincentPoradProjekt.name" header="Projekt" width={vincentProjektColumnWidth} columnViewStatus={sluzbaEnum === SluzbaEnum.vincentPoradenstvo} dropdownInFilter={true} dropdownFilter={Utils.enumFilter(EnumEnum.vincentPoradProjekt)}/>
                </XLazyDataTable>
            </div>
        );
    }

    const renderMobile = () => {

        const [fieldList, minLength]: [string[], number] = Utils.klientSluzbaFieldListForAutoComplete();

        // potrebujeme pridat medzi full text stlpce aj stlpce zobrazovane v expanded casti
        const fullTextSearchColumns: string[] = ["datum", "klientSluzba.klient.menoPriezviskoPrezyvka"];
        if (sluzbaEnum === SluzbaEnum.streetwork) {
            fullTextSearchColumns.push("vykazStreetwork.tim.name");
            if (multilineSwitchValue !== "singleLine") {
                fullTextSearchColumns.push("vykazStreetwork.vykazXUserList.xUser.name");
            }
        }
        if (multilineSwitchValue !== "singleLine") {
            fullTextSearchColumns.push("vseobecnyZapis");
            fullTextSearchColumns.push("atributy");
        }

        // width="calc(100vw - 15.3rem)" nefunguje, XLazyDataTable alebo DataTable nam to meni na calc(100vw)
        let columnKlientWidth: number = window.screen.availWidth - XUtils.toPX0("15.5rem"); // 15.5rem - pokusne zistene
        if (sluzbaEnum !== SluzbaEnum.streetwork) {
            columnKlientWidth += XUtils.toPX0("6rem"); // nemame stlpec Tim
        }

        return (
            <div>
                <XLazyDataTable entity="Zapis" stateKey={props.stateKey ?? `zapis-browse-${sluzbaEnum}`}
                                label={Utils.isSluzbaNoclaharen() ? "Ošetr..." : "Zápisy"} labelStyle={{marginRight: '0rem'}}
                                sortField={[{field: "datum", order: -1}, {field: "id", order: -1}]} rows={30}
                                fields={sluzbaEnum === SluzbaEnum.streetwork ? ["vykazStreetwork.vykazXUserList.xUser.name"] : undefined}
                                showExpandedRow={showExpandedRowMobile} rowExpansionTemplate={rowExpansionTemplateMobile}
                                filters={props.filters} customFilter={Utils.filterCurrentSluzba()}
                                fullTextSearch={fullTextSearchColumns}
                                onAddRow={onAddRow} onEdit={onEdit} removeRow={true}
                                appButtons={props.filters ? <XButton key="naspet" label="Naspäť" onClick={() => (props as any).openForm(null)}/> : undefined} /* ak sme prisli z VykazStreetworkHlavickaForm, zobrazime button Naspet */
                                displayed={props.displayed}
                                rowClassName={rowClassNameMobile}
                                multilineSwitch={true} multilineSwitchValue={[multilineSwitchValue, setMultilineSwitchValue]}>
                    <XLazyColumn field="datum" header="Dátum" autoFilter={true}/>
                    <XLazyColumn field="vykazStreetwork.tim.name" header="Tím" width="6rem" columnViewStatus={sluzbaEnum === SluzbaEnum.streetwork}
                                 dropdownInFilter={true} dropdownFilter={Utils.enumFilter(EnumEnum.streetworkTim)} autoFilter={true}/>
                    <XLazyColumn field="klientSluzba.klient.menoPriezviskoPrezyvka" header="Klient" width={`${columnKlientWidth}px`} autoFilter={true}
                                 autoComplete={{
                                     assocField: "klientSluzba", field: fieldList, filter: Utils.filterCurrentSluzba(), valueForm: <KlientSluzbaForm/>,
                                     lazyLoadMaxRows: 15, minLength: minLength, scrollHeight: "50rem"
                                 }}/>
                </XLazyDataTable>
            </div>
        );
    }

    if (!XUtils.isMobile()) {
        return renderDesktop();
    }
    else {
        return renderMobile();
    }
}
